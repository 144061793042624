
//requires jquery and d3js
function initializeComponentDataTree(initData, nodeCallback) {
    let d3Data = {
        m: [20, 120, 20, 120],
        w: null,
        h: null,
        i: 0,
        root: initData,
        tree: null,
        diagonal: null
    };
    d3Data.w = $('#companion-data-tree-container').width() - d3Data.m[1] - d3Data.m[3];
    d3Data.h = $('#companion-data-tree-container').height() - d3Data.m[0] - d3Data.m[2];

    d3Data.tree = d3.layout.tree()
        .size([d3Data.h, d3Data.w]);

    d3Data.diagonal = d3.svg.diagonal()
        .projection(function (d) { return [d.y, d.x]; });
    d3Data.vis = d3.select("#companion-data-tree").append("svg:svg")
        .attr("width", d3Data.w + d3Data.m[1] + d3Data.m[3])
        .attr("height", d3Data.h + d3Data.m[0] + d3Data.m[2])
        .attr("id", "companion-data-tree-svg")
        .append("svg:g")
        .attr("transform", "translate(" + d3Data.m[3] + "," + d3Data.m[0] + ")");

    d3Data.root.x0 = d3Data.h / 2;
    d3Data.root.y0 = 0;
    return d3Data;
}



function toggleAllComponentDataNodes(d) {
    if (d.children) {
        d.children.forEach(toggleAll);
        toggleComponentDataNode(d);
    }
}

// Toggle children.
function toggleComponentDataNode(d) {
    if (d.children) {
        d._children = d.children;
        d.children = null;
    } else {
        d.children = d._children;
        d._children = null;
    }
}


function updateComponentDataNode(source, d3Data, nodeCallback) {
    let duration = d3.event && d3.event.altKey ? 5000 : 500;

    // Compute the new tree layout.
    let nodes = d3Data.tree.nodes(d3Data.root).reverse();

    //default value 180
    // Normalize for fixed-depth.
    nodes.forEach(function (d) {
        let depthMultiplier = 90;
        switch (d.type) {
            case 'companion-data-category':
                depthMultiplier = 150;
                break;
            case 'companion-data-category-value':
                depthMultiplier = 180;
                break;
            case 'companion-data-category-next':
                depthMultiplier = 200;
                break;
            case 'companion-data-collection':
                depthMultiplier = 220;
                break;
            case 'companion-data-collection-next':
                depthMultiplier = 230;
                break;
            case 'companion-data-model':
                depthMultiplier = 200;
                break;
            default:
                depthMultiplier = 90;
        }
        // if(d.name === 'ab1_gasinj/ab1_gasinj_gsg.afi')
        //     debugger;
        d.y = d.depth * depthMultiplier;
    });

    // Update the nodes…
    let node = d3Data.vis.selectAll("g.node")
        .data(nodes, function (d) { return d.id || (d.id = ++d3Data.i); });

    // Enter any new nodes at the parent's previous position.
    let nodeEnter = node.enter().append("svg:g")
        .attr("class", "node")
        .attr("transform", function (d) { return "translate(" + source.y0 + "," + source.x0 + ")"; })
        .on("click", function (d) {
            if (nodeCallback && nodeCallback(d)) {

            } else {
                toggleComponentDataNode(d); updateComponentDataNode(d, d3Data, nodeCallback);
            }
        });

    nodeEnter.append("svg:circle")
        .attr("r", 1e-6)
        .style("fill", function (d) { return d._children ? "lightsteelblue" : "#fff"; })
        .style("display", function (d) {
            if (d.type === 'companion-data-model')
                return "none";
            else
                return "block";
        });

    nodeEnter.append('a')
        .attr('xlink:href', function (d) {
            let url = window.location.href.lastIndexOf('#') === window.location.href.length - 1 ? window.location.href : window.location.href + '#';
            if (d.type === 'companion-data-model')
                url = `/collection/${d.data.collectionId}?m=${d.data.modelId}`;
            return url;
        })
        .append("svg:text")
        .attr("x", function (d) { return d.children || d._children ? -10 : 10; })
        .attr("dy", ".35em")
        .attr("text-anchor", function (d) { return d.children || d._children ? "end" : "start"; })
        .text(function (d) { return d.name; })
        .style('fill', function (d) {
            return d.type !== 'bu' && d.type !== 'root' ? 'black' : '#999';
        })
        .style("fill-opacity", 1e-6);

    nodeEnter.append("svg:title")
        .text(function (d) {
            return d.description;
        });

    // Transition nodes to their new position.
    let nodeUpdate = node.transition()
        .duration(duration)
        .attr("transform", function (d) { return "translate(" + d.y + "," + d.x + ")"; });

    nodeUpdate.select("circle")
        .attr("r", 6)
        .style("fill", function (d) { return d._children ? "lightsteelblue" : "#fff"; });

    nodeUpdate.select("text")
        .style("fill-opacity", 1);

    // Transition exiting nodes to the parent's new position.
    let nodeExit = node.exit().transition()
        .duration(duration)
        .attr("transform", function (d) { return "translate(" + source.y + "," + source.x + ")"; })
        .remove();

    nodeExit.select("circle")
        .attr("r", 1e-6);

    nodeExit.select("text")
        .style("fill-opacity", 1e-6);

    // Update the links…
    let link = d3Data.vis.selectAll("path.link")
        .data(d3Data.tree.links(nodes), function (d) { return d.target.id; });

    // Enter any new links at the parent's previous position.
    link.enter().insert("svg:path", "g")
        .attr("class", "link")
        .attr("d", function (d) {
            let svgWidth = $("#companion-data-tree-svg").width();
            let nodeMaxWidth = d.target.y + d3Data.m[1] + d3Data.m[3];
            if (nodeMaxWidth > svgWidth) {
                $("#companion-data-tree-svg").width(nodeMaxWidth);
            }

            let svgHeight = $("#companion-data-tree-svg").height();
            let nodeMaxHeight = d.target.x + d3Data.m[0] + d3Data.m[2];
            if (nodeMaxHeight > svgHeight) {
                $("#companion-data-tree-svg").height(nodeMaxHeight);
            }

            let o = { x: source.x0, y: source.y0 };
            return d3Data.diagonal({ source: o, target: o });

        })
        .transition()
        .duration(duration)
        .attr("d", d3Data.diagonal);

    // Transition links to their new position.
    link.transition()
        .duration(duration)
        .attr("d", d3Data.diagonal);

    // Transition exiting nodes to the parent's new position.
    link.exit().transition()
        .duration(duration)
        .attr("d", function (d) {
            let o = { x: source.x, y: source.y };
            return d3Data.diagonal({ source: o, target: o });
        })
        .remove();

    // Stash the old positions for transition.
    nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
    });
}

